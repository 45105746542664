import React from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";

const HeroImage = () => (
    <StaticQuery
        query={graphql`
            query {
                file(relativePath: { eq: "gift.jpg" }) {
                    childImageSharp {
                        # Specify the image processing specifications right in the query.
                        # Makes it trivial to update as your page's design changes.
                        fixed(width: 60) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Img
                fixed={data.file.childImageSharp.fixed}
                // objectFit="cover"
                // objectPosition="50% 50%"
                alt="Il biologico di tutti"
            />
        )}
    />
);

export default HeroImage;
