import React, { useState } from "react";
import "../css/form.css";
import addToMailchimp from "../../plugins/gatsby-plugin-mailchimp";
import { navigate } from "gatsby";
import HeroImage from "./HeroImage";

const Form = () => {
    const [name, setName] = useState("");
    // const [surname, setSurname] = useState("");
    // const [location, setLocation] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [subscribed, setSubscribed] = useState(false);

    function handleSubmit(event) {
        event.preventDefault();

        // if no blanks TODO

        addToMailchimp(email, {
            FNAME: name,
            // LNAME: surname,
            // COMPAGNIA: location,
        })
            .then((data) => {
                if (data.result === "error") {
                    throw data;
                }
                // success - redirect to success page
                setSubscribed(true);
                if (typeof window !== "undefined") {
                    if (window.fbq != null) {
                        window.fbq("track", "CompleteRegistration", {
                            content_name: "Landing Cassette",
                            status: true,
                        });
                    }
                    if (window.gtag != null) {
                        window.gtag("event", "registrazione_landing_cassette_giugno_2021");
                    }
                }
                navigate("/grazie");
            })
            .catch((error) => {
                setError("Si è verificato un errore.");
                // console.log(error.msg);
            });
    }

    function handleNameChange(event) {
        setName(event.currentTarget.value);
        setError("");
    }

    // function handleSurnameChange(event) {
    //     setSurname(event.currentTarget.value);
    //     setError("");
    // }

    // function handleLocationChange(event) {
    //     setLocation(event.currentTarget.value);
    //     setError("");
    // }

    function handleEmailChange(event) {
        setEmail(event.currentTarget.value);
        setError("");
    }

    return (
        <>
            <div className="text-center mt-1">
                <HeroImage />
            </div>
            <form className="" onSubmit={handleSubmit}>
                <div className="md-input-main mb-4">
                    <div className="md-input-box">
                        <input
                            id="name"
                            name="name"
                            type="text"
                            className="md-input"
                            placeholder=" "
                            required
                            onChange={handleNameChange}
                            // hasError={error}
                            value={name}
                        />
                        <label htmlFor="name" className="md-label">
                            Il tuo nome
                        </label>
                        <div className="md-input-underline" />
                    </div>
                </div>
                {/* <div className="md-input-main mb-4">
                <div className="md-input-box">
                    <input
                        id="surname"
                        name="surname"
                        type="text"
                        className="md-input"
                        placeholder=" "
                        required
                        onChange={handleSurnameChange}
                        // hasError={error}
                        value={surname}
                    />
                    <label htmlFor="surname" className="md-label">
                        Cognome
                    </label>
                    <div className="md-input-underline" />
                </div>
            </div> */}

                {/* <div className="md-input-main mb-4">
                <div className="md-input-box">
                    <input
                        id="location"
                        name="location"
                        type="text"
                        className="md-input"
                        placeholder=" "
                        required
                        onChange={handleLocationChange}
                        // hasError={error}
                        value={location}
                    />
                    <label htmlFor="location" className="md-label">
                        Località
                    </label>
                    <div className="md-input-underline" />
                </div>
            </div> */}

                <div className="md-input-main mb-4">
                    <div className="md-input-box">
                        <input
                            id="email"
                            name="email"
                            type="email"
                            className="md-input"
                            placeholder=" "
                            required
                            onChange={handleEmailChange}
                            // hasError={error}
                            value={email}
                        />
                        <label htmlFor="email" className="md-label">
                            La tua email
                        </label>
                        <div className="md-input-underline" />
                    </div>
                </div>

                <div className="mb-4">
                    <div className="">
                        <input
                            id="consent"
                            name="consent"
                            type="checkbox"
                            className=""
                            placeholder=" "
                            required
                        />
                        <label htmlFor="consent" className="px-3">
                            Accetto il trattamento dei miei dati
                        </label>
                        <div className="" />
                    </div>
                </div>

                {error && (
                    <div
                        style={{ maxWidth: "15rem" }}
                        className="error mb-3 mx-auto max-w-full text-red-600 text-center"
                        dangerouslySetInnerHTML={{ __html: error }}
                    />
                )}

                <div className="place-items-center mb-3 text-center">
                    <button
                        // className="m-3 place-items-center uppercase font-semibold underline"
                        className="hover:bg-transparent bg-black shadow-lg hover:text-black text-white py-2 px-4 border hover:border-black border-transparent"
                        type="submit"
                        hasError={error}
                        subscribed={subscribed}
                        disabled={subscribed}
                    >
                        <h2 className="max-w-4xl text-xl mx-auto uppercase font-bold font-sans">
                            Iscriviti
                        </h2>
                    </button>
                </div>
            </form>
        </>
    );
};

export default Form;
